import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['nav', 'navItem', 'tabPane'];

  connect() {
    const id = this.#idFromHash() || this.navItemTargets[0].children[0].dataset.tabPaneId;
    this.#updateSelection(id);
  }

  select(event) {
    const id = event.currentTarget.dataset.tabPaneId;
    this.#updateSelection(id);
  }

  open() {
    this.navTarget.classList.toggle('open');
  }

  #idFromHash() {
    return document.location.hash.replace('#', '');
  }

  #updateSelection(id) {
    this.navItemTargets.forEach(el => {
      el.classList.toggle('is-selected', el.children[0].dataset.tabPaneId === id);
    });
    this.tabPaneTargets.forEach(el => {
      el.classList.toggle('active', el.id === id);
    });
  }
}
